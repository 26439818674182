import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import PagesLayout from '../components/Layout/PagesLayout';
import Sara from '../assets/img/Sara_photo.jpeg';
import BACP from '../assets/img/BACP_logo.png';

import './about.scss';

const About = () => (
	<PagesLayout>
		<div className="about">
			<div className="about__avatar">
				<Avatar alt="Sara" src={Sara} style={{ width: 180, height: 180 }} />
			</div>

			<div>
				<p>Hi, I’m Sara</p>
				<p>
					I am a qualified Psychodynamic Counsellor with the belief that everyone has the
					ability to grow and reach their potential and lead happy and fulfilling lives.
					At times life can be difficult and we can feel lost, helpless or distressed. The
					aim of counselling is to provide you with a confidential, supportive,
					non-judgemental space for you to explore your thoughts and feelings in safety
					and assist your healing, understanding and growth.
				</p>

				<p>
					I have worked with a range of issues, from people that have suffered with
					anxiety, depression, abuse, bereavement, stress, infertility, post-traumatic
					stress disorder, trust, self-esteem and addictions.
				</p>

				<p>
					The name of my practice is Mind Focus Counselling. To me this represents growth,
					strength, focus, hope and new beginnings. At Mind Focus Counselling, I offer
					short term focused counselling and long term in-depth counselling depending on
					your needs. I also offer some low cost therapy depending on availability and am
					happy to accommodate shift workers.
				</p>
			</div>
			<div className="about__bacp">
				<img alt="BACP" src={BACP} />
			</div>
		</div>
	</PagesLayout>
);

// const About = PagesLayout(_About);

export default About;
