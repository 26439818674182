import React from 'react';
import PagesLayout from '../components/Layout/PagesLayout';

const WhatToExpect = _ => (
	<PagesLayout>
		<div>
			<p>
				Once we have met and we feel we would like to work together, we commit to meet
				weekly for a duration of 50 minutes.
				<strong>
					This could be short term (10 sessions) or long-term. We can discuss this at our
					first appointment and we can review the process regularly.
				</strong>
			</p>
			<p>
				As a psychotherapist I will provide you with the support you need and guide you
				along a path of positive change. Therapy can help deal with difficult events and
				overcome challenging times in life: the right approach will enable you to regain
				your equilibrium and get back to normal, but feeling stronger and revitalised.
			</p>
			<h2>How long do we need</h2>
			<p>
				The first appointment is an assessment for both of us. Where we explore what has
				brought you, what the problems are and the context, and what you are hoping to get
				out of counselling. I then share with you how I think I can help and you decide if
				this is right for you.
			</p>
			<p>
				If you feel that we have a rapport and you feel confident working with me that is a
				good sign, but if you feel there is not then there is no commitment to continue.
			</p>
			<p>
				Having had an assessment (face to face or telephone) and we agree to work together,
				we decide whether our work will be on either a short term or long term basis. The
				time scale we work to will depend on the presenting issues and how long we will need
				to get you to a place where you feel your problems are resolved and you feel happy
				and content.
			</p>
		</div>
	</PagesLayout>
);

export default WhatToExpect;
