import { useMediaQuery, useTheme } from '@material-ui/core';

import React from 'react';

// import images from '../../assets/img/*';

const Background = ({ id }) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<div style={{ position: 'fixed', zIndex: -1 }}>
			<img
				style={{
					maxWidth: '100%',
					width: '100%',
					...(matches && { height: '100vh' }),
					objectFit: 'cover',
				}}
				src={require(`../../assets/img/bg_${id}.jpg`)}
				alt="bg"
			/>
		</div>
	);
};

export default Background;
