import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Home, About } from './containers';
import Services from './containers/Services';
import Fees from './containers/Fees';
import WhatToExpect from './containers/WhatToExpect';
import Contact from './containers/Contact';
import Testimonials from './containers/Testimonials';

const Routes = () => (
	<Switch>
		<Route exact path="/" component={Home} />
		<Route path="/about" component={About} />
		<Route path="/services" component={Services} />
		<Route path="/fees" component={Fees} />
		<Route path="/what-to-expect" component={WhatToExpect} />
		<Route path="/testimonials" component={Testimonials} />
		<Route path="/contact" component={Contact} />
	</Switch>
);

export default Routes;
