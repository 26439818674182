import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Routes from './Routes';
import './app.scss';

import Background from './components/Background/Background';
import Navigation from './components/Navigation/Navigation';
import { getRouteName } from './helpers/route-name.helper';

console.log('REACT_APP_GREETINGS! >>>>>>>>>>>>>>', process.env.REACT_APP_GREETINGS);

const App = ({ location }) => (
	<div className="app">
		<Background id={getRouteName(location.pathname)} />
		<AppBar position="static">
			<div className="app__header">
				<div className="app__header__logo" />
				<div>
					<Navigation />
				</div>
			</div>
		</AppBar>
		<Container maxWidth="lg">
			<div className="app__content">
				<Routes />
			</div>
		</Container>
		<footer className="app__footer">
			<Container maxWidth="lg">
				<div className="app__footer__container">
					<div>BACP Registered 377540</div>
					<div>{`© ${new Date().getFullYear()}`}</div>
					<div>
						<Button type="tel" variant="" color="primary">
							<a className="app__footer__container__tel" href="tel:07375838093">
								M: 07375838093
							</a>
						</Button>
					</div>
				</div>
			</Container>
		</footer>
	</div>
);

// const App = () => <div>asdsdasd</div>;

export default withRouter(App);
