import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Divider from '@material-ui/core/Divider';

import './pages-layout.scss';
import { getRouteName } from '../../helpers/route-name.helper';

const PagesLayoutContainer = ({ children, location }) => {
	const [open, setOpen] = React.useState(true);

	const useStyles = makeStyles(theme => ({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		paper: {
			backgroundColor: 'rgba(27, 31, 34, 0.85)',
			width: '100%',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(4, 4),
			borderRadius: '10px',
			opacity: '.5',
		},
	}));

	const handleOpen = () => {
		// setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// const { open } = this.state;
	return (
		<div className="pages-layout">
			<Fade direction="up" in={open} mountOnEnter unmountOnExit timeout={500}>
				<div className={useStyles().paper}>
					<div className="pages-layout__heading">{getRouteName(location.pathname)}</div>
					<Divider light={false} variant="fullWidth" style={{ marginBottom: '15px' }} />
					{children}
				</div>
			</Fade>
		</div>
	);
};

PagesLayoutContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

const PagesLayout = withRouter(PagesLayoutContainer);

export default PagesLayout;
