import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export default function Contact() {
	const classes = useStyles();
	const initialData = { firstName: '', lastName: '', email: '', message: '' };

	const [formData, setFormData] = useState(initialData);
	const [deliveryMessage, setDeliveryMessage] = useState('');
	const [open, setOpen] = useState(false);
	const [formLoading, setFormLoading] = useState(false);

	const formConfig = {
		headers: {
			'Content-type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Headers': '*',
		},
	};

	const handleChange = (e) => {
		e.preventDefault();
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setFormLoading(true);
		try {
			const res = await axios.post(
				'https://peaceful-williams-306bba.netlify.app/.netlify/functions/server/postmessage',
				formData,
				formConfig
			);
			setDeliveryMessage(res.data.message);
		} catch (err) {
			setDeliveryMessage(err.message);
		}
		setFormLoading(false);
		// reset form:
		setFormData({
			...formData,
			...initialData,
		});
		setOpen(true);
	};

	return (
		<Container component="main" maxWidth="xs">
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<ContactMailOutlinedIcon />
				</Avatar>

				<ValidatorForm
					// ref="form"
					onSubmit={handleSubmit}
				>
					<Grid container spacing={1}>
						<Grid item xs={12} sm={6}>
							<TextValidator
								variant="filled"
								color="secondary"
								fullWidth
								onChange={handleChange}
								value={formData.firstName}
								name="firstName"
								label="First Name"
								autoFocus
								validators={['required']}
								errorMessages={['this field is required']}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextValidator
								variant="filled"
								color="secondary"
								fullWidth
								onChange={handleChange}
								value={formData.lastName}
								name="lastName"
								label="Last Name"
								validators={['required']}
								errorMessages={['this field is required']}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextValidator
								variant="filled"
								color="secondary"
								fullWidth
								label="Email Address"
								name="email"
								onChange={handleChange}
								value={formData.email}
								validators={['required', 'isEmail']}
								errorMessages={['this field is required', 'email is not valid']}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextValidator
								variant="filled"
								color="secondary"
								fullWidth
								multiline
								name="message"
								label="Message"
								type="textArea"
								onChange={handleChange}
								value={formData.message}
								validators={['required']}
								errorMessages={['this field is required']}
							/>
						</Grid>
					</Grid>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="secondary"
						className={classes.submit}
						disabled={formLoading}
						endIcon={!formLoading && <ArrowForwardIosOutlinedIcon />}
					>
						{!formLoading ? 'Submit' : <CircularProgress size={35} />}
					</Button>
				</ValidatorForm>
			</div>
			<Box mt={5} style={{ background: 'rgba(255,255,255,0.8)', padding: 10 }}>
				If you are interested in discussing what you want out of counselling, please contact
				me. I aim to reply to all enquiries within 24 hours.
			</Box>
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={open}
				autoHideDuration={4000}
				onClose={handleClose}
				ContentProps={{
					'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">{deliveryMessage}</span>}
				onExited={() => setDeliveryMessage('')}
				action={[
					<IconButton
						key="close"
						aria-label="close"
						color="inherit"
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>,
				]}
			/>
		</Container>
	);
}
