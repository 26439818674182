import React from 'react';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import PagesLayout from '../components/Layout/PagesLayout';
import './home.scss';

const Home = () => (
	<PagesLayout>
		<div className="home">
			<div>
				<p>
					At times life can be difficult and we can feel lost, helpless or distressed. The
					aim of counselling is to provide you with a confidential, supportive,
					non-judgemental space for you to explore your thoughts and feelings in safety
					and assist your healing, understanding and growth. I am a qualified
					Psychodynamic Counsellor based in Goffs Oak with off-street parking.
				</p>
				<p>
					I offer short term and long term therapy, with the facility/flexibility to
					accommodate shift workers.
				</p>
			</div>
			<div className="home__btns">
				<NavLink className="navigation__items__menu__item--contact" to={'/contact'}>
					<Button
						color="secondary"
						variant="contained"
						className="navigation__items__menu__login"
						style={{ borderRadius: 50, padding: '10px 50px', margin: 10 }}
					>
						Contact
					</Button>
				</NavLink>

				<NavLink className="navigation__items__menu__item--contact" to={'/about'}>
					<Button
						color="secondary"
						variant="contained"
						className="navigation__items__menu__login"
						style={{ borderRadius: 50, padding: '10px 50px', margin: 10 }}
					>
						About
					</Button>
				</NavLink>
			</div>
		</div>
	</PagesLayout>
);

export default Home;
