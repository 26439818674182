import React from 'react';
import Table from '@material-ui/core/Table';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PagesLayout from '../components/Layout/PagesLayout';

const useStyles = makeStyles(theme => ({
	table: {
		margin: `0 auto`,
		maxWidth: 350,
		background: theme.palette.primary.main,
	},
}));

const Services = _ => {
	const classes = useStyles();
	return (
		<PagesLayout>
			<div>
				<p>
					I offer long-term and short-term counselling and psychotherapy to individuals
					and can help with a wide range of issues.
				</p>
				<p>Sessions can be conducted face-to-face or via video call or telephone.</p>
				<p>
					My areas of expertise include (and are not limited to): Depression; Anxiety;
					Work-related stress; Loss and bereavement; Pregnancy, motherhood, parenting;
					addiction and trauma.
				</p>
				<p>
					When we first meet we will explore your feelings and talk through what you want
					to achieve and your needs. The journey from not feeling yourself to discovering
					yourself is different for every single one of us. I believe I can provide the
					support needed to move through a crisis; allow you time and space to get to know
					yourself, and as such allow you to develop a greater sense of well being.
				</p>
				<br />
				<TableContainer className={classes.table} component={Paper}>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Type of session provided:</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell align="left">Short-term counselling</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="left">Long-term counselling</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="left">Online counselling</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="left">Telephone counselling</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="left">Face to face counselling</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				<br />
				<h3>Accessibility:</h3>
				<p>
					My consulting room is on the ground floor, but there is a single step to enter
					the premises. I have off-street parking.
				</p>
			</div>
		</PagesLayout>
	);
};

export default Services;
