import React from 'react';
import PagesLayout from '../components/Layout/PagesLayout';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import data from '../data/testimonials.json';
import TestimonialHolder from '../components/TestimonialHolder';

const Testimonials = () => {
	var settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};
	return (
		<PagesLayout>
			<Slider {...settings}>
				{data.map((details) => (
					<TestimonialHolder
						key={details.client}
						client={details.client}
						feedback={details.feedback}
					/>
				))}
			</Slider>
		</PagesLayout>
	);
};

export default Testimonials;
