import { Avatar } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		'& > *': {
			margin: theme.spacing(1),
		},
	},
	large: {
		width: theme.spacing(8),
		height: theme.spacing(8),
		backgroundColor: theme.palette.secondary.main,
	},
}));

const TestimonialHolder = ({ client, feedback }) => {
	const classes = useStyles();
	return (
		<div style={{ padding: 8 }}>
			<div className={classes.root}>
				<Avatar
					className={classes.large}
					style={{ marginBottom: 10 }}
					color="primary"
					variant="circular"
				>
					{client}
				</Avatar>
			</div>
			<p style={{ whiteSpace: 'break-spaces' }}>{feedback}</p>
		</div>
	);
};

export default TestimonialHolder;
