import { createMuiTheme } from '@material-ui/core/styles';

// $color-primary: #afd275;
// $color-secondary: #7e685a;
// $color-tertiary: #e7717d;
const theme = createMuiTheme({
	typography: {
		useNextVariants: true,
	},
	palette: {
		primary: {
			light: '#afd275',
			main: '#afd275',
			dark: '#006db3',
			contrastText: '#fff',
		},
		secondary: {
			light: '#7e685a',
			main: '#7e685a',
			dark: '#006db3',
			contrastText: '#fff',
		},
		tertiary: {
			light: '#e7717d',
			main: '#e7717d',
			dark: '#006db3',
			contrastText: '#fff',
		},
	},
	shape: {
		borderRadius: 4,
	},
	props: {
		MuiButtonBase: {
			disableRipple: false,
		},
	},
	overrides: {
		MuiDrawer: {
			paper: {
				minWidth: 256,
			},
			paperAnchorDockedLeft: {
				borderRight: 'none',
			},
		},
		MuiDivider: {
			root: {
				backgroundColor: '#afd275',
			},
		},
		MuiButton: {
			outlinedPrimary: {},
			// outlinedSecondary: {
			// 	color: green,
			// 	backgroundColor: '#e7717d',
			// },
			containedSecondary: {
				backgroundColor: '#e7717d',
				'&:hover': {
					backgroundColor: '#d9626d',
				},
			},

			text: {},
			root: {
				'&:hover': {
					// backgroundColor: '#afd275',
					color: '#fff',
				},
			},
		},
	},
});

export default theme;
