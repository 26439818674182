import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import './navigation.scss';

import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const links = [
	{ title: 'About', path: '/about' },
	{ title: 'Services', path: '/services' },
	{ title: 'What to expect', path: '/what-to-expect' },
	{ title: 'Fees', path: '/fees' },
	{ title: 'Testimonials', path: '/testimonials' },
];

const getNav = (setOpen, isDesktop = false) => (
	<div
		className={classNames('navigation__items', {
			[`navigation__items--mobile`]: !isDesktop,
		})}
	>
		<NavLink
			to="/"
			onClick={() => {
				setOpen(false);
			}}
		>
			<div className="navigation__items__logo" />
		</NavLink>

		<div
			className={classNames('navigation__items__menu', {
				[`navigation__items__menu--mobile`]: !isDesktop,
			})}
		>
			{links.map((link) => (
				<Button key={link.path} style={{ marginRight: '10px', padding: 0 }}>
					<NavLink
						className="navigation__items__menu__item"
						to={link.path}
						activeClassName="is-active"
						onClick={() => {
							setOpen(false);
						}}
					>
						{link.title}
					</NavLink>
				</Button>
			))}
			{/* <Button
				color="secondary"
				variant="contained"
				className="navigation__items__menu__login"
				style={{ borderRadius: 50, padding: '10px 50px' }}
				startIcon={<PermIdentityOutlinedIcon />}
			> */}
			<NavLink
				className="navigation__items__menu__item--contact"
				to={'/contact'}
				onClick={() => {
					setOpen(false);
				}}
			>
				<Button
					color="secondary"
					variant="contained"
					className="navigation__items__menu__login"
					style={{ borderRadius: 50, padding: '10px 50px' }}
					startIcon={<PermIdentityOutlinedIcon />}
				>
					Contact
				</Button>
			</NavLink>
			{/* </Button> */}
		</div>
	</div>
);

const Navigation = () => {
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
	const [open, setOpen] = useState(false);
	return (
		<AppBar position="fixed" color="inherit" className="navigation">
			{isDesktop && getNav(setOpen, isDesktop)}
			{!isDesktop && (
				<div>
					<IconButton
						edge="start"
						style={{ position: 'absolute', right: 0, top: 15 }}
						color="primary"
						aria-label="menu"
						onClick={() => setOpen(true)}
					>
						<MenuIcon />
					</IconButton>
					<NavLink to="/">
						<div className="navigation__items__logo" />
					</NavLink>
					<Drawer
						open={open}
						transitionDuration={400}
						// onClose={toggleDrawer('left', false)}
						// onOpen={toggleDrawer('left', true)}
					>
						<IconButton
							edge="start"
							style={{ position: 'absolute', right: 0, top: 15 }}
							color="inherit"
							aria-label="menu"
							onClick={() => setOpen(false)}
						>
							<CloseOutlinedIcon />
						</IconButton>
						{getNav(setOpen, isDesktop)}
					</Drawer>
				</div>
			)}
		</AppBar>
	);
};

export default Navigation;
