import React from 'react';
import PagesLayout from '../components/Layout/PagesLayout';

const Fees = () => (
	<PagesLayout>
		<div>
			<p>Each session lasts 50 minutes and will cost £65 a session.</p>
			<p>Introductory calls for 15 minutes are free.</p>
			<p>You can pay by cash or bank transfer. </p>
			<p>
				I do reserve some places for people on lower incomes. Please enquire if I have
				availability.
			</p>
		</div>
	</PagesLayout>
);

export default Fees;
